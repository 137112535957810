import React from 'react';

const Dropdown = ({ label, options, name, value, onChange, error }) => {
  const borderClass = error ? 'border-red-500' : 'border-heading';

  return (
    <div className="w-full h-auto flex flex-col justify-start items-start gap-1 relative">
      <div className="w-full px-4 justify-start items-center gap-2.5 inline-flex">
        <label htmlFor={name} className="text-label text-sm font-normal leading-normal">
          {label}
        </label>
      </div>
      <div
        className={`w-full p-4 rounded-2xl border ${borderClass} inline-flex items-center`}
        data-tooltip-id={name}
        data-tooltip-content={error}
      >
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full text-primary text-base font-normal leading-snug focus:outline-none bg-transparent"
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dropdown;
