import React from 'react';

const EventItem = ({ title, place, startDate, endDate, status, statusColor }) => {
  return (
    <div className="self-stretch p-4 bg-white rounded-2xl border border-zinc-200 flex justify-between items-center">
      <div className="px-4 py-2 flex justify-start items-center gap-2.5">
        <div className={`text-neutral-900 text-base font-semibold`}>{title}</div>
      </div>
      <div className="w-[220px] text-neutral-900 text-sm font-normal">{place}</div>
      <div className="w-20 text-neutral-900 text-sm font-normal">{startDate}</div>
      <div className="w-20 text-neutral-900 text-sm font-normal">{endDate}</div>
      <div className="h-10 py-2 rounded-3xl flex justify-start items-center gap-1">
        <div className="w-4 h-4 p-2.5 flex justify-center items-center gap-2.5">
          <div className={`w-2 h-2 bg-${statusColor} rounded-full`}></div>
        </div>
        <div className="text-neutral-900 text-sm font-normal">{status}</div>
      </div>
    </div>
  );
};

export default EventItem;
