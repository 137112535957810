import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DesktopHeader from './desktop/login/DesktopHeader';
import DesktopLoginForm from './desktop/login/DesktopLoginForm';

const Login = () => {
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    if (window.innerWidth >= 768) {
      // Si es versión de escritorio
      setIsForgotPasswordOpen(true);
    } else {
      // Si es versión móvil
      navigate('/reset-code-mobile');
    }
  };

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordOpen(false);
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      {/* Cabecera para móviles */}
      <div className="md:hidden bg-custom-gradient"></div>
      {/* Formulario para móviles */}
      <div className="md:hidden h-full bg-custom-gradient2"></div>
      {/* Cabecera para escritorio */}
      <div className="hidden md:flex">
        <DesktopHeader />
      </div>
      {/* Formulario para escritorio */}
      <div className="hidden md:flex w-[70%] bg-custom-gradient2">
        <DesktopLoginForm onForgotPasswordClick={handleForgotPassword} />
      </div>
    </div>
  );
};

export default Login;
