import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ImageComponent from 'components/background/imageComponent';
import imageP from 'components/background/png';
import { Event, CloudUpload, PersonAdd, Badge, ArrowBack } from '@mui/icons-material';
import NavItem from 'components/navItem';
import Button from 'components/globalButton';

const Sidebar = () => {
  const navigate = useNavigate();
  const { eventoId } = useParams();
  const location = useLocation(); // Obtiene la URL actual

  const navItems = [
    {
      label: 'Asistentes',
      icon: Event,
      id: 'asistentes',
      route: `/eventos/${eventoId}/asistentes`,
    },
    {
      label: 'Carga Masiva de Asistentes',
      icon: CloudUpload,
      id: 'carga-masiva',
      route: `/eventos/${eventoId}/asistentes/carga-masiva`,
    },
    {
      label: 'Carga Individual de Asistentes',
      icon: PersonAdd,
      id: 'carga-individual',
      route: `/eventos/${eventoId}/asistentes/carga-individual`,
    },
    {
      label: 'Credenciales',
      icon: Badge,
      id: 'credenciales',
      route: `/eventos/${eventoId}/asistentes/credenciales`,
    },
  ];

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-sidebar bg-sidebar p-4 flex flex-col">
      {/* Botón de retroceso reutilizando el componente globalButton */}
      <div className="cursor-pointer flex items-center mb-4">
        <Button label="Volver" icon={ArrowBack} onClick={handleBackClick} />
      </div>

      {/* Sección de Navegación */}
      <div className="flex flex-col gap-4">
        {navItems.map(item => (
          <NavItem
            key={item.id}
            label={item.label}
            Icon={item.icon}
            isSelected={location.pathname === item.route} // Comparar la ruta actual con la URL del navItem
            onClick={() => navigate(item.route)}
          />
        ))}
      </div>

      {/* Imagen del escudo */}
      <div className="flex justify-center mt-auto mb-4">
        <ImageComponent src={imageP.escudoFvf} alt="Escudo FVF" className="w-3/4 object-contain" />
      </div>
    </div>
  );
};

export default Sidebar;
