// DesktopLoginForm.js
import React, { useState } from 'react';
import CustomButton from 'components/button/';
import { useNavigate } from 'react-router-dom';
import { useLogin, texts } from './utils';

const DesktopLoginForm = ({ onForgotPasswordClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const { handleLogin, handleRegister } = useLogin(navigate);

  const onSubmit = e => {
    e.preventDefault();
    handleLogin(email, password);
  };

  return (
    <div className="w-full h-full bg-white rounded-tl-3xl rounded-bl-3xl flex flex-col justify-center items-center gap-12 p-6">
      <div className="flex flex-col gap-4 items-center w-full max-w-[600px]">
        <div className="text-neutral-900 text-[28px] font-bold leading-9">{texts.loginTitle}</div>
        <form onSubmit={onSubmit} className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-1 w-full">
            <div className="px-4 flex justify-start items-center gap-2.5">
              <div className="text-neutral-900 text-sm font-normal leading-normal">
                {texts.emailLabel}
              </div>
            </div>
            <div className="p-4 rounded-2xl border border-neutral-900 flex justify-start items-center gap-2.5 w-full">
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={texts.emailPlaceholder}
                className="w-full text-zinc-400 text-base font-normal leading-snug focus:outline-none"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1 w-full">
            <div className="px-4 flex justify-start items-center gap-2.5">
              <div className="text-neutral-900 text-sm font-normal leading-normal">
                {texts.passwordLabel}
              </div>
            </div>
            <div className="p-4 rounded-2xl border border-neutral-900 flex justify-start items-center gap-2.5 w-full">
              <input
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder={texts.passwordPlaceholder}
                className="w-full text-zinc-400 text-base font-normal leading-snug focus:outline-none"
              />
            </div>
          </div>
          <div
            className="px-4 flex justify-start items-center gap-2.5 cursor-pointer"
            // onClick={onForgotPasswordClick}
          >
            <div className="w-6 h-6 relative">
              <div className="w-[18px] h-[18px] left-[3px] top-[3px] absolute rounded-full border-2 border-black"></div>
              <div className="w-[3px] h-[3px] left-[8px] top-[8px] absolute bg-black rounded-full"></div>
              <div className="w-[3px] h-[3px] left-[13px] top-[8px] absolute bg-black rounded-full"></div>
              <div className="w-1.5 h-1.5 left-[15px] top-[20px] absolute origin-top-left -rotate-180 rounded-full border-2 border-black"></div>
            </div>
            <div className="text-neutral-900 text-base font-normal leading-snug">
              {texts.forgotPassword}
            </div>
          </div>
          <div className="flex justify-center gap-6">
            <CustomButton
              type="submit"
              variant="black"
              className="text-white text-base font-normal leading-snug"
            >
              {texts.loginButton}
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DesktopLoginForm;
