import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'components/globalButton';
import InputField from 'components/input';
import { createAttendee } from 'services/upload/attendees';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  nombres: Yup.string().required('El nombre es obligatorio'),
  apellidos: Yup.string().required('El apellido es obligatorio'),
  nro_documento: Yup.string().required('El número de cédula es obligatorio'),
  gerencia: Yup.string().required('La gerencia es obligatoria'),
  departamento: Yup.string().required('El departamento es obligatorio'),
  telefono: Yup.string().required('El teléfono es obligatorio'),
  proveedor: Yup.string().required('El proveedor es obligatorio'),
  funcion: Yup.string().required('La función es obligatoria'),
  email: Yup.string().email('El email no es válido').required('El email es obligatorio'),
});

const AddAttendeeForm = () => {
  const { eventoId } = useParams();

  const initialFormData = {
    nombres: '',
    apellidos: '',
    nro_documento: '',
    gerencia: '',
    departamento: '',
    telefono: '',
    proveedor: '',
    funcion: '',
    email: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    validationSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          await createAttendee(eventoId, formData);
          toast.success('Asistente registrado exitosamente.');

          // Limpiar los campos después del registro exitoso
          setFormData(initialFormData);
        } catch (error) {
          toast.error('Ocurrió un error al registrar el asistente. Intente nuevamente.');
          console.error('Error en el registro:', error);
        }
      })
      .catch(err => {
        const validationErrors = err.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});

        toast.error('Por favor, complete los campos obligatorios correctamente.');

        setErrors(validationErrors);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white max-w-4xl py-10 px-6 md:px-10 rounded-xl flex flex-col gap-6 mx-auto"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Parte Izquierda */}
        <div className="flex flex-col gap-4">
          <InputField
            label="Nombre"
            placeholder="Ingrese el nombre"
            name="nombres"
            type="text"
            value={formData.nombres}
            onChange={handleChange}
            error={errors.nombres}
          />
          <InputField
            label="Apellido"
            placeholder="Ingrese el apellido"
            name="apellidos"
            type="text"
            value={formData.apellidos}
            onChange={handleChange}
            error={errors.apellidos}
          />
          <InputField
            label="Número de cédula"
            placeholder="Ingrese el número de cédula"
            name="nro_documento"
            type="text"
            value={formData.nro_documento}
            onChange={handleChange}
            error={errors.nro_documento}
          />
          <InputField
            label="Correo electrónico"
            placeholder="Ingrese el correo electrónico"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
          />
          <InputField
            label="Número de teléfono"
            placeholder="Ingrese el número de teléfono"
            name="telefono"
            type="tel"
            value={formData.telefono}
            onChange={handleChange}
            error={errors.telefono}
          />
        </div>

        {/* Parte Derecha */}
        <div className="flex flex-col gap-4">
          <InputField
            label="Gerencia"
            placeholder="Ingrese la gerencia"
            name="gerencia"
            type="text"
            value={formData.gerencia}
            onChange={handleChange}
            error={errors.gerencia}
          />
          <InputField
            label="Departamento"
            placeholder="Ingrese el departamento"
            name="departamento"
            type="text"
            value={formData.departamento}
            onChange={handleChange}
            error={errors.departamento}
          />
          <InputField
            label="Proveedor"
            placeholder="Ingrese el proveedor"
            name="proveedor"
            type="text"
            value={formData.proveedor}
            onChange={handleChange}
            error={errors.proveedor}
          />
          <InputField
            label="Función"
            placeholder="Ingrese la función"
            name="funcion"
            type="text"
            value={formData.funcion}
            onChange={handleChange}
            error={errors.funcion}
          />
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <Button label="Enviar" />
      </div>
    </form>
  );
};

export default AddAttendeeForm;
