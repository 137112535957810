import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { handleNextStep, handlePreviousStep, handleFinish, renderStep } from './utils';

const Load = () => {
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showConfirmSuccess, setShowConfirmSuccess] = useState(false);
  const [state, setState] = useState({ step: 1, asistenteId: null });
  const [uploadedData, setUploadedData] = useState([]);

  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleFileUpload = data => {
    setUploadedData(data);
    handleNextStep(setState);
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      {/* Pantalla de mobil */}
      <div className="md:hidden "></div>
      <div className="md:hidden h-full ">
        <div className="w-full h-full "></div>
      </div>

      {/* Pantalla de escritorio */}
      <div className="hidden md:flex w-[100%] h-screen flex-col ">
        {renderStep(
          state.step,
          setState,
          handlePreviousStep,
          handleNextStep,
          state.asistenteId // Pasar el asistenteId desde el estado
        )}
      </div>
    </div>
  );
};

export default Load;
