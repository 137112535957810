import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, IconButton, InputBase } from '@mui/material';
import {
  Search as SearchIcon,
  ArrowDropDown as ArrowDropDownIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Header = ({ view, setActiveContent }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userName, setUserName] = useState('Desconocido');
  const [userRole, setUserRole] = useState('Invitado');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    const storedUserRole = localStorage.getItem('userRole');

    if (storedUserName) {
      setUserName(storedUserName);
    }

    const roles = {
      1: 'Administrador',
      2: 'Supervisor',
      3: 'Visor',
    };

    if (roles[storedUserRole]) {
      setUserRole(roles[storedUserRole]);
    }
  }, []);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = event => {
    event.preventDefault();
    console.log('Buscar', view, ':', searchTerm);
  };

  const handleProfileClick = () => {
    handleMenuClose();
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  return (
    <div className="self-stretch p-6 border-b border-gray-200 flex justify-between items-center">
      <form
        onSubmit={handleSearchSubmit}
        className="h-14 flex flex-col justify-start items-start gap-1"
      >
        <div className="w-[447px] h-14 p-4 rounded-2xl border border-neutral-400 flex justify-start items-center gap-2.5">
          <SearchIcon className="text-gray-500" />
          <InputBase
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={`Buscar ${view}`}
            className="grow shrink basis-0 text-zinc-500 text-base font-normal focus:outline-none"
          />
        </div>
      </form>
      <div className="px-4 py-2 flex justify-start items-center gap-2">
        <div className="w-10 h-10 p-2 bg-gradient-to-tl from-[#7B1E24] via-[#8A2B31] to-[#7B1E24] rounded-full flex justify-center items-center gap-2.5">
          <div className="text-white text-base font-semibold">LG</div>
        </div>

        <div className="flex flex-col justify-start items-start">
          <div className="w-custom text-indigo-950 text-base font-normal">{userName}</div>
          <div className="w-custom text-red text-xs font-normal">{userRole}</div>
        </div>
        <IconButton onClick={handleMenuOpen} className="w-6 h-6 relative">
          <ArrowDropDownIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleProfileClick}>
            <AccountCircleIcon className="mr-2" />
            Perfil
          </MenuItem>
          <MenuItem onClick={handleLogoutClick}>
            <ExitToAppIcon className="mr-2" />
            Cerrar Sesión
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
