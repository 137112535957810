import { safeImageImport } from 'components/background/utils';
const fallbackImage = 'img/respaldo/fall-back.webp';

const imageP = {
  fondoVinotinto: safeImageImport('img/png/fondo-vinotinto.png', fallbackImage),
  escudoFvf: safeImageImport('img/png/escudo-fvf.png', fallbackImage),
  vinotintoFutbol: safeImageImport('img/png/vinotinto-futbol.png', fallbackImage),
  logoFvf: safeImageImport('img/png/logo-fvf.png', fallbackImage),
};

export default imageP;
