import React from 'react';
import ImageComponent from 'components/background/imageComponent';
import imageP from 'components/background/png';

const EventInfoImage = () => {
  return (
    <div className="w-full lg:w-1/2 h-screen flex justify-center items-center">
      <div className="w-full max-w-[300px] sm:max-w-[350px] h-auto lg:h-[400px] py-4">
        <ImageComponent className="w-full h-full object-contain" src={imageP.escudoFvf} alt="Evento" />
      </div>
    </div>
  );
};

export default EventInfoImage;
