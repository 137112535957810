import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputField from 'components/input';
import SectionHeaderTitle from 'components/SectionHeaderTitle';
import Button from 'components/globalButton';
import Dropdown from 'components/dropdown';
import { getAllCredentialTypes } from 'services/credencial';
import { updateAttendeeZones, getAllAccessZones } from 'services/upload/attendees';
import { toast } from 'react-toastify';

/**
 * TODO: Move this mapping object to the backend side of things in order to keep one single
 * source of truth, right now there are no validations in backend to forbid selecting other
 * zone values for an specific sector
 */
const SECTOR_TO_ZONES_MAP = {
  'FULL ACCESO': '1, 2, 3, 4, 5, 6, 7, 8, 9',
  LIMITADO: '3, 4, 5, 6, 7, 8, 9',
  RESTRINGIDO: '3, 4, 6, 7, 8',
  NORTE: '3, 4',
  SUR: '3, 4',
  ESTE: '3, 4',
  OESTE: '3, 4',
};

const INITIAL_SECTOR = 'FULL ACCESO';

const AssignZonesForm = () => {
  const { eventoId, asistenteId } = useParams();
  const [zones, setZones] = useState(SECTOR_TO_ZONES_MAP[INITIAL_SECTOR]);
  const [sector, setSector] = useState(INITIAL_SECTOR);
  const [credentialTypeId, setCredentialTypeId] = useState(''); // Nuevo estado para el tipo de credencial
  const [isLoading, setIsLoading] = useState(false);
  const [availableZones, setAvailableZones] = useState([]);
  const [credentialTypes, setCredentialTypes] = useState([]); // Estado para almacenar los tipos de credenciales
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAvailableZones = async () => {
      try {
        const accessZonesData = await getAllAccessZones();
        setAvailableZones(accessZonesData.map(accessZoneData => accessZoneData.zona));
      } catch (error) {
        toast.error('Error al obtener las zonas disponibles');
        console.error('Error al obtener las zonas disponibles:', error);
      }
    };

    fetchAvailableZones();
  }, []);

  useEffect(() => {
    const fetchCredentialTypes = async () => {
      try {
        const credentialTypesData = await getAllCredentialTypes();
        setCredentialTypes(credentialTypesData);
      } catch (error) {
        toast.error('Error al obtener los tipos de credenciales');
        console.error('Error al obtener los tipos de credenciales:', error);
      }
    };

    fetchCredentialTypes();
  }, []);

  const handleSectorChange = event => {
    const nextSectorValue = event.target.value;
    setSector(nextSectorValue);
    setZones(SECTOR_TO_ZONES_MAP[nextSectorValue]);
  };

  const handleCredentialTypeChange = event => {
    setCredentialTypeId(event.target.value);
  };

  const handleBack = () => {
    if (!isLoading) {
      navigate(-1);
    }
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const zonesArray = zones.split(',').map(zone => Number(zone.trim()));

      if (!zonesArray.every(zone => availableZones.includes(zone))) {
        toast.error('Una o más zonas no son válidas.');
        setIsLoading(false);
        return;
      }

      if (!credentialTypeId) {
        toast.error('Por favor, selecciona un tipo de credencial válido.');
        setIsLoading(false);
        return;
      }

      const data = { sector, zones: zonesArray, credentialTypeId };
      await updateAttendeeZones(eventoId, asistenteId, data);

      toast.success('Zonas actualizadas correctamente');
      navigate(-1);
    } catch (error) {
      toast.error('Hubo un error al asignar las zonas. Por favor, intenta de nuevo.');
      console.error('Error al asignar zonas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-white">
      <div className="w-full h-screen rounded-tl-3xl rounded-bl-3xl flex flex-col items-center gap-4">
        <SectionHeaderTitle title="Asignar zonas" />

        {/* Dropdown para seleccionar el tipo de credencial */}
        <div className="max-w-[350px] w-full">
          <Dropdown
            label="Tipo de Credencial"
            name="credentialType"
            value={credentialTypeId}
            onChange={handleCredentialTypeChange}
            options={[
              { value: '', label: 'Selecciona un tipo de credencial' },
              ...credentialTypes.map(type => ({
                value: type.tipo_credencial_id,
                label: `${type.descripcion} - ${type.codigo}`,
              })),
            ]}
            placeholder="Selecciona un tipo de credencial"
          />
        </div>

        {/* Dropdown para seleccionar el sector */}
        <div className="max-w-[350px] w-full">
          <Dropdown
            label="Sector"
            name="sector"
            value={sector}
            onChange={handleSectorChange}
            options={[
              { value: 'FULL ACCESO', label: 'Full acceso' },
              { value: 'LIMITADO', label: 'Limitado' },
              { value: 'RESTRINGIDO', label: 'Restringido' },
              { value: 'NORTE', label: 'Norte' },
              { value: 'SUR', label: 'Sur' },
              { value: 'ESTE', label: 'Este' },
              { value: 'OESTE', label: 'Oeste' },
            ]}
          />
        </div>

        {/* Campo de texto para las zonas de acceso */}
        <div className="max-w-[350px] w-full">
          <InputField
            label={`Zonas de acceso (máximo ${availableZones.length} zonas disponibles)`}
            placeholder="1,2,3 ..."
            name="zones"
            type="text"
            onChange={() => {}}
            value={zones}
            disabled
          />
        </div>

        {/* Botones de Confirmar y Atrás */}
        <div className="flex gap-4 mt-8">
          <Button
            label="Atrás"
            onClick={handleBack}
            bgColor="bg-white"
            textColor="text-black"
            isLoading={isLoading}
          />

          <Button
            label="Confirmar"
            onClick={handleConfirm}
            isLoading={isLoading}
            disabled={!availableZones.length || !credentialTypeId}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignZonesForm;
