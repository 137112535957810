// src/components/DesktopHeader.js
import React from 'react';
import SideSection from 'components/login/SideSection';

const DesktopHeader = () => {
  return (
    <div className="w-full h-screen bg-custom-gradient flex">
      <SideSection />
    </div>
  );
};

export default DesktopHeader;
