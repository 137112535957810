import axios from 'axios';

const apiClient = axios.create({
  baseURL: '/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // El servidor respondió con un código de estado fuera del rango de 2xx
      return Promise.reject(error.response);
    } else if (error.request) {
      // La solicitud fue hecha pero no hubo respuesta
      return Promise.reject(new Error('No se recibió respuesta del servidor'));
    } else {
      // Algo sucedió al configurar la solicitud que desencadenó un error
      return Promise.reject(new Error('Error en la configuración de la solicitud'));
    }
  }
);

export default apiClient;
