// src/components/BackgroundSection.js

import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const BackgroundSection = ({ image, children }) => {
  return (
    <div className="background-section" style={{ backgroundImage: `url(${image})` }}>
      <div className="background-overlay">{children}</div>
    </div>
  );
};

BackgroundSection.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default BackgroundSection;
