import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from 'components/sidebar';

const SidebarLayout = () => {
  return (
    <div className="flex h-screen">
      <div className="w-sidebar flex-shrink-0">
        <Sidebar />
      </div>
      <div className="flex-grow overflow-auto bg-gray-100">
        <Outlet />
      </div>
    </div>
  );
};

export default SidebarLayout;
