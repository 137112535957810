import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InputField from 'components/input';
import { FaUpload } from 'react-icons/fa';
import { uploadAttendees } from 'services/upload/attendees';
import { toast } from 'react-toastify';
import SectionHeaderTitle from 'components/SectionHeaderTitle';

const AttendeesFileUploadForm = () => {
  const { eventoId } = useParams();
  const [file, setFile] = useState(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Por favor seleccione un archivo.');
      return;
    }

    try {
      const response = await uploadAttendees(eventoId, file);
      toast.success('El archivo se ha subido correctamente.');
    } catch (error) {
      if (error.statusCode === 400 && error.message === 'Solo se permiten archivos CSV') {
        toast.error(
          'El archivo seleccionado no es un CSV. Por favor, suba un archivo con extensión .csv.'
        );
      } else {
        toast.error('Ha ocurrido un error al subir el archivo. Inténtelo nuevamente.');
      }
      console.error(error);
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#feffff]">
      <div className="w-full h-screen rounded-tl-3xl rounded-bl-3xl flex flex-col items-center gap-12">
        <SectionHeaderTitle title="Carga masiva" />

        <div className="max-w-[300px] w-full">
          <InputField
            label="Carga Masiva"
            placeholder="Busque su archivo .csv o .xlsx de forma local"
            name="fileUpload"
            type="file"
            icon={<FaUpload className="text-gray-400" />}
            onChange={handleFileChange}
            accept=".csv, .xlsx"
          />
        </div>

        <div className="flex gap-4 mt-8">
          <button
            className="w-24 h-[54px] bg-white rounded-3xl border border-button text-primary text-base flex items-center justify-center"
            onClick={() => console.log('Atrás clicked')}
          >
            Atrás
          </button>
          <button
            className="w-[134px] h-[54px] bg-button rounded-3xl border border-button text-white text-base flex items-center justify-center"
            onClick={handleUpload}
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendeesFileUploadForm;
