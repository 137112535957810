import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageComponent from 'components/background/imageComponent';
import imageP from 'components/background/png';
import CustomButton from 'components/button/';
import { handleScrollEvent } from './utils';
import EventLeft from './information';
import EventRight from './calendar';

const EventHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cleanup = handleScrollEvent(setScrolled);
    return cleanup;
  }, []);

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div
      className="relative w-full h-[645px] bg-cover bg-center"
      style={{ backgroundImage: `url(${imageP.vinotintoFutbol})` }}
    >
      <div className="w-full h-full bg-black bg-opacity-60 flex flex-col justify-start items-center">
        <div
          className={`w-full px-6 sm:px-10 md:px-20 lg:px-40 py-4 border-b ${
            scrolled ? 'bg-black bg-opacity-90' : 'bg-transparent'
          } transition-all duration-300 border-amber-50 flex justify-between items-center fixed top-0 left-0 z-50`}
        >
          <div className="w-[50px] h-[60px] relative">
            <ImageComponent
              src={imageP.logoFvf}
              alt="indentificador"
              className="w-full h-full"
            ></ImageComponent>
          </div>
          <div className="flex flex-col sm:flex-row justify-start items-center gap-4 sm:gap-8 md:gap-12">
            <CustomButton
              variant="whiteOutlined"
              className="text-base font-normal leading-snug"
              onClick={handleLoginClick}
            >
              Iniciar Sesión
            </CustomButton>
          </div>
        </div>
        <div className="w-full h-[559px] pt-[86px] p-6 sm:p-10 md:p-20 lg:p-[100px] flex flex-col justify-start items-start gap-4 sm:gap-8">
          <div className="w-full flex flex-col md:flex-row justify-start items-center gap-4 sm:gap-8">
            <EventLeft />
            <EventRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
