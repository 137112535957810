import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#ffffff',  // Ajusta el color principal
    // },
    secondary: {
      main: '#004d40', // Ajusta el color secundario
    },
    background: {
      default: '#ffffff', // Fondo principal
      paper: '#f4f4f4', // Fondo de los elementos tipo papel
    },
    text: {
      primary: '#333333', // Color principal del texto
      secondary: '#757575', // Color secundario del texto
    },
    custom: {
      transparent: 'rgba(0, 0, 0, 0)', // Color transparente personalizado
      scrolled: '#333333', // Color cuando se desplaza
    },
    teal: {
      main: '#008080', // Define el color teal aquí
      800: '#006666', // Define un tono más oscuro de teal
    },
  },
  typography: {
    fontFamily: '"Onest", Arial, sans-serif', // Actualiza la fuente
    h1: {
      fontSize: '3rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.25rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px', //redondeo deseado para todos los botones
        },
      },
    },
  },
});

export default theme;
