import React from 'react';
import CustomButton from 'components/button/';
import { eventDetails } from 'components/landingPage/eventAdd/utils';

const EventInfo = () => {
  return (
    <div className="w-full h-full flex flex-col justify-start items-start gap-6">
      <div className="text-white text-sm font-normal font-['Archivo Black'] uppercase leading-relaxed tracking-wide">
        {eventDetails.header}
      </div>
      <div className="text-white text-5xl lg:text-6xl font-normal font-['Archivo Black'] leading-tight lg:leading-tight">
        {eventDetails.title}
      </div>
      <div className="text-white text-3xl lg:text-4xl font-normal font-['Archivo Black'] leading-tight">
        {eventDetails.subtitle}
      </div>
      <div className="flex flex-col sm:flex-row justify-start items-start gap-6"></div>
    </div>
  );
};

export default EventInfo;
