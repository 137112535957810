// src/utils.js

import { useState } from 'react';

// Pagination hook
export const usePagination = (initialPage = 0, initialRowsPerPage = 5) => {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

// Events data
export const events = [
  {
    title: 'Venezuela vs Uruguay Clasificatoria 2024',
    place: 'Estadio Monumental de Maturín',
    startDate: '10/09/2024',
    endDate: '10/09/2024',
    status: 'Culminado',
    statusColor: 'bg-blue-500',
    id: 1,
  },
  {
    title: 'Venezuela vs Argentina Clasificatoria 2024',
    place: 'Estadio Monumental de Maturín',
    startDate: '10/10/2024',
    endDate: '10/10/2024',
    status: 'En Proceso',
    statusColor: 'bg-green-500',
    id: 2,
  },
  {
    title: 'Venezuela vs Brazil Clasificatoria 2024',
    place: 'Por definirse',
    startDate: '14/11/2022',
    endDate: '14/11/2022',
    status: 'Próximo',
    statusColor: 'bg-yellow-500',
    id: 3,
  },
];

// Get paginated events
export const getPaginatedEvents = (events, page, rowsPerPage) => {
  return events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

// Styles object
export const mainContentStyles = {
  container: {
    width: '100%',
    height: '100%',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  paper: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderRadius: '16px',
    padding: '16px',
    gap: '16px',
  },
  eventBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    border: '1px solid #e0e0e0',
    gap: '16px',
  },
};
