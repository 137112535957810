import apiClient from 'services/AxiosConfig';

// Método para obtener todos los asistentes
export const getAllAsistentes = async () => {
  try {
    const response = await apiClient.get('/upload/usuarios');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para obtener un asistente por ID
export const getAsistenteById = async id => {
  try {
    const response = await apiClient.get(`/upload/usuarios/${id}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para obtener un asistente por credencialId
export const getAsistenteByCredencialId = async credencialId => {
  try {
    const response = await apiClient.get(`/upload/usuarios/credencial/${credencialId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para obtener todos los niveles de acceso
export const getAllAccessLevels = async () => {
  try {
    const response = await apiClient.get('/credential-access/all');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para obtener los niveles de acceso de un asistente por su ID
export const getAccessLevelsForAsistente = async asistenteId => {
  try {
    const response = await apiClient.get(`/credential-access/asistente/${asistenteId}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};
