import React from 'react';
import CustomButton from 'components/button/';
import ImageComponent from 'components/background/imageComponent';
import imageP from 'components/background/png';

const SideSection = () => {
  return (
    <div className="w-full pl-10 pt-14 pb-20 flex flex-col justify-between items-center gap-12">
      <div className="flex justify-center items-center w-full">
        <div className="w-[150px] h-[100px] sm:w-[200px] sm:h-[130px] md:w-[250px] md:h-[170px] lg:w-[300px] lg:h-[200px] relative">
          <ImageComponent
            src={imageP.escudoFvf}
            alt="Escudo FVF"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 text-center">
        <div className="text-white text-[28px] font-bold leading-9">Iniciar Sesión</div>
        <div className="text-white text-lg font-normal leading-normal">
          Aquí podrás cargar la información sobre el personal que debe ser acreditado.
        </div>
      </div>
      <div className="flex gap-6"></div>
    </div>
  );
};

export default SideSection;
