import React from 'react';
import SectionHeader from 'components/SectionHeaderTitle';
import AddAttendeeForm from 'components/forms/AddAttendeeForm';

const AddAttendeePage = () => {
  return (
    <>
      <SectionHeader title="Carga individual" />
      <AddAttendeeForm />
    </>
  );
};

export default AddAttendeePage;
