import MassUploadResultStep from 'components/load/desktop/add/massive';
import MassUploadCompletionStep from 'components/load/desktop/add/finish';

export const handleNextStep = (setCurrentStep, asistenteId) => {
  setCurrentStep(prevState => ({
    ...prevState,
    step: prevState.step + 1,
    asistenteId, // Guardar el asistenteId en el estado
  }));
};

export const handlePreviousStep = setCurrentStep => {
  setCurrentStep(prevState => ({
    ...prevState,
    step: prevState.step > 1 ? prevState.step - 1 : prevState.step,
  }));
};

export const handleFinish = () => {
  console.log('Proceso finalizado');
};

export const renderStep = (
  currentStep,
  setCurrentStep,
  handlePreviousStep,
  handleNextStep,
  asistenteId
) => {
  switch (currentStep) {
    case 1:
      return (
        <MassUploadResultStep
          onBack={() => handlePreviousStep(setCurrentStep)}
          onContinue={id => {
            setCurrentStep({ step: 2, asistenteId: id });
          }}
        />
      );
    case 2:
      return (
        <MassUploadCompletionStep
          onFinish={handleFinish}
          asistenteId={asistenteId} // Pasar el asistenteId aquí
          onBack={() => handlePreviousStep(setCurrentStep)}
        />
      );
    default:
      return <div></div>;
  }
};
