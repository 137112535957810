import React from 'react';
import { Box, Typography, TablePagination, Paper } from '@mui/material';
import { events, getPaginatedEvents, usePagination, mainContentStyles } from './utils';
import { useNavigate } from 'react-router-dom';

const MainContent = () => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const navigate = useNavigate();

  const paginatedEvents = getPaginatedEvents(events, page, rowsPerPage);

  const handleRegisterClick = eventoId => {
    navigate(`/eventos/${eventoId}/asistentes`);
  };

  return (
    <Box sx={mainContentStyles.container}>
      <Typography variant="h4" sx={{ color: 'neutral.900', fontWeight: 'bold' }}>
        Partidos
      </Typography>
      <Paper elevation={0} sx={{ ...mainContentStyles.paper, padding: 0 }}>
        <Box sx={{ overflowX: 'auto' }}>
          <Box sx={{ display: 'flex', minWidth: '900px', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ ...mainContentStyles.headerBox, marginBottom: '16px' }}>
              <Typography
                variant="body1"
                sx={{ flex: 3, color: 'neutral.900', fontWeight: 'bold' }}
              >
                Evento
              </Typography>
              <Typography
                variant="body1"
                sx={{ flex: 2, color: 'neutral.900', fontWeight: 'bold' }}
              >
                Lugar
              </Typography>
              <Typography
                variant="body1"
                sx={{ flex: 1, color: 'neutral.900', fontWeight: 'bold' }}
              >
                Inicio
              </Typography>
              <Typography
                variant="body1"
                sx={{ flex: 1, color: 'neutral.900', fontWeight: 'bold' }}
              >
                Final
              </Typography>
              <Typography
                variant="body1"
                sx={{ flex: 1, color: 'neutral.900', fontWeight: 'bold' }}
              >
                Estado
              </Typography>
            </Box>
            {paginatedEvents.map(event => (
              <Box
                key={event.id}
                sx={{
                  ...mainContentStyles.eventBox,
                  marginBottom: '16px',
                  padding: '16px',
                  cursor: 'pointer', // Ahora todas las filas son clickeables
                }}
                onClick={() => handleRegisterClick(event.id)}
              >
                <Typography
                  variant="body1"
                  sx={{ flex: 3, color: 'neutral.900', fontWeight: 'bold' }}
                >
                  {event.title}
                </Typography>
                <Typography variant="body2" sx={{ flex: 2, color: 'neutral.900' }}>
                  {event.place}
                </Typography>
                <Typography variant="body2" sx={{ flex: 1, color: 'neutral.900' }}>
                  {event.startDate}
                </Typography>
                <Typography variant="body2" sx={{ flex: 1, color: 'neutral.900' }}>
                  {event.endDate}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <span className={`w-3 h-3 rounded-full ${event.statusColor} mr-2`}></span>
                  <Typography variant="body2" sx={{ color: 'neutral.900' }}>
                    {event.status}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={events.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default MainContent;
