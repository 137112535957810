import React from 'react';
import MainContent from './main';
import BodyHead from 'components/main/header/Header';

const ContentSwitcher = ({ activeContent, setActiveContent }) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-[20%]">
        <BodyHead view={activeContent.view} setActiveContent={setActiveContent} />
      </div>
      <div className="h-[80%] overflow-auto">
        {activeContent.view === 'evento' && <MainContent />}
      </div>
    </div>
  );
};

export default ContentSwitcher;
