import apiClient from 'services/AxiosConfig';

// Método para generar la previsualización de una credencial
export const previewCredential = async credencialId => {
  try {
    const response = await apiClient.get(`/credenciales/generate/preview/${credencialId}`, {
      responseType: 'arraybuffer', // Asegura que la respuesta se trate como un archivo binario
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    return url; // Devuelve la URL del blob para que pueda ser previsualizada
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para descargar la credencial generada
export const downloadCredential = async credencialId => {
  try {
    const response = await apiClient.get(`/credenciales/generate/pdf/${credencialId}`, {
      responseType: 'arraybuffer',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `credencial-${credencialId}.pdf`); // Nombre del archivo de descarga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para generar la credencial desde el asistente ID y descargarla
export const generateCredentialFromAsistente = async asistenteId => {
  try {
    const response = await apiClient.post(
      `/credenciales/generate/from-asistente/${asistenteId}`,
      {},
      {
        responseType: 'arraybuffer',
      }
    );

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `credencial-${asistenteId}.pdf`); // Nombre del archivo de descarga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Método para generar credenciales para todos los asistentes y descargar el PDF
export const generateCredentialsForAllAsistentes = async () => {
  try {
    const response = await apiClient.post(
      '/credenciales/generate/for-all-asistentes',
      {},
      {
        responseType: 'arraybuffer', // Asegura que la respuesta se maneje como binario
      }
    );

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'credenciales.zip'); // Nombre del archivo de descarga
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { status: 200 }; // Retorna el estado para manejar en la capa superior
    } else if (response.status === 499) {
      return { status: 499 }; // Retorna el estado para manejar en la capa superior
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    throw error; // Lanza el error para manejar en la capa superior
  }
};

// Método para cancelar la generación de credenciales
export const cancelGeneration = async () => {
  try {
    const response = await apiClient.post('/credenciales/cancel-generation');
    if (response.status === 200) {
      console.log('Cancelación solicitada exitosamente.');
      return response; // Devuelve la respuesta para manejarla en `handleCancel`
    } else {
      console.error(
        'Error inesperado al cancelar la generación:',
        response?.status || 'Respuesta no definida'
      );
      return null;
    }
  } catch (error) {
    if (error.response) {
      console.error('Error en la respuesta:', error.response);
    } else {
      console.error('Error en la solicitud:', error.message);
    }
    throw error;
  }
};

// Método para obtener todos los tipos de credenciales
export const getAllCredentialTypes = async () => {
  try {
    const response = await apiClient.get('/tipo-credencial', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw {
        statusCode: error.response.data.statusCode || error.response.status,
        message: error.response.data.message || 'Error al obtener los tipos de credenciales',
        error: error.response.data.error || 'Error desconocido',
      };
    } else {
      throw {
        statusCode: 500,
        message: 'Error desconocido. Por favor intenta de nuevo más tarde.',
      };
    }
  }
};
