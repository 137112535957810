export const baseStyles = {
  padding: '10px 20px',
  fontSize: '16px',
  minWidth: '150px',
  borderRadius: '9999px',
  display: 'flex',
  justifyContent: 'center',
  border: '2px solid transparent',
  transition: 'background-color 0.3s, border-color 0.3s',
};

export const menuButtonStyles = {
  padding: '10px 30px',
  fontSize: '14px',
  minWidth: '200px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'flex-start',
  border: '2px solid transparent',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderColor: 'inherit',
  },
};

export const variantStyles = {
  black: {
    backgroundColor: 'black',
    color: 'white',
    borderColor: 'black',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.95)',
      borderColor: 'black',
    },
  },
  vinotinto: {
    backgroundColor: '#520e27',
    color: 'white',
    borderColor: '#520e27',
    '&:hover': {
      backgroundColor: 'rgba(82, 14, 39, 0.95)',
      borderColor: '#520e27',
    },
  },

  whiteOutlined: {
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderColor: 'white',
    },
  },
  white: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderColor: 'white',
      color: 'black',
    },
    borderRadius: '50px',
  },
  whiteSn: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'white',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderColor: 'rgba(0, 0, 0, 0)',
      color: 'white',
    },
  },
  whiteBor: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'rgba(0, 0, 0, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderColor: 'rgba(0, 0, 0, 0.8)',
      color: 'black',
    },
  },
  whiteGre: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: '#070707',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderColor: '#070707',
      color: 'black',
    },
  },
  whiteGradientBorder: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid transparent',
    borderColor: 'rgba(4, 71, 75, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderColor: 'rgba(9,95,100, 0.8)',
      color: 'black',
    },
  },
  whiteWithBorder: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderColor: 'black',
    },
    borderRadius: '50px',
  },
  transparentWithBorder: {
    backgroundColor: 'transparent',
    color: 'white',
    borderColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderColor: 'white',
      color: 'white',
    },
    borderRadius: '50px',
  },
  menuButton: menuButtonStyles,
};
