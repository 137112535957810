// src/components/landingPage/eventAdd/utils.js

export const eventDetails = {
  title: 'Venezuela vs Argentina 2024',
  subtitle: 'Registro de Credenciales',
  header: 'Registro',
  date: 'Octubre 10, 2024',
  time: '6:00 PM',
  location: 'Estadio Monumental',
  city: 'Maturín, Venezuela',
};

export const handleScrollEvent = setScrolled => {
  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
};
