import React, { useState } from 'react';
import Slider from './header/Slider';
import ContentSwitcher from './deskstop/';

const Dashboard = () => {
  const [activeContent, setActiveContent] = useState({
    view: 'evento',
    stand: null,
  });

  const setActiveView = (view, stand = null) => {
    setActiveContent({ view, stand });
  };

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      {/* Formulario para móviles */}
      <div className="md:hidden h-screen bg-custom-gradient2"></div>

      {/* Cabecera para escritorio */}
      <div className="hidden md:flex w-[15%]">
        <Slider className="" setActiveContent={setActiveView} />
      </div>
      {/* Formulario para escritorio */}
      <div className="hidden md:flex w-[85%] ">
        <ContentSwitcher activeContent={activeContent} setActiveContent={setActiveView} />
      </div>
    </div>
  );
};

export default Dashboard;
