import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Backdrop } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import { getAsistenteById, getAccessLevelsForAsistente } from 'services/asistente';
import CustomButton from 'components/button/';
import { generateCredentialFromAsistente } from 'services/credencial';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import SectionHeader from 'components/SectionHeaderTitle';

const MassUploadCompletionStep = ({ asistenteId, onBack }) => {
  const [asistenteData, setAsistenteData] = useState(null);
  const [accessLevels, setAccessLevels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAsistenteData = async () => {
      try {
        const data = await getAsistenteById(asistenteId);
        setAsistenteData(data);

        // Obtener los niveles de acceso del asistente
        const accessLevelsData = await getAccessLevelsForAsistente(asistenteId);
        setAccessLevels(accessLevelsData);
      } catch (error) {
        console.error('Error fetching asistente data or access levels:', error);
        setAsistenteData(null);
        setAccessLevels([]);
        toast.error('Error al obtener los datos del asistente o niveles de acceso.');
      }
    };

    if (asistenteId) {
      fetchAsistenteData();
    }
  }, [asistenteId]);

  const getZonaStyles = zona => {
    return accessLevels.some(level => level.zona == zona)
      ? 'bg-yellow-300 text-black'
      : 'bg-gray-300 text-black';
  };

  const handleGenerateCredential = async () => {
    if (asistenteId) {
      setLoading(true);
      try {
        await generateCredentialFromAsistente(asistenteId);
        toast.success('Credencial generada y descargada exitosamente.');
      } catch (error) {
        console.error('Error generating credential:', error);
        toast.error('Hubo un error al generar la credencial. Inténtalo de nuevo.');
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Asistente ID no disponible');
      toast.error('Asistente ID no disponible');
    }
  };

  return (
    <div className="w-full h-full py-14 bg-[#feffff] rounded-tl-3xl rounded-bl-3xl flex flex-col justify-center items-center gap-12">
      <Box className="flex justify-start items-start gap-4">
        <div className="flex items-center">
          <div className="h-14 pr-2 flex items-center gap-2">
            <div className="w-14 h-14 p-2.5 bg-[#520e27] rounded-full flex justify-center items-center">
              <FaCheckCircle className="text-white text-2xl" />
            </div>
            <div className="text-primary text-lg font-normal font-['Archivo Black'] leading-normal">
              Previsualización de la Credencial
            </div>
          </div>
        </div>
      </Box>

      <div className="text-center text-xl font-semibold">
        <SectionHeader title="Credencial" />
      </div>

      <div className="bg-credential text-white rounded-xl p-6 w-96 flex flex-col items-center">
        <div className="w-full text-left mb-4">
          <p className="text-base font-bold">
            Nombre:{' '}
            <span className="font-normal">
              {asistenteData?.nombres ? asistenteData.nombres.toUpperCase() : 'Cargando...'}
            </span>
          </p>
          <p className="text-base font-bold">
            Apellido:{' '}
            <span className="font-normal">
              {asistenteData?.apellidos ? asistenteData.apellidos.toUpperCase() : 'Cargando...'}
            </span>
          </p>
          <p className="text-sm font-bold">
            Función:{' '}
            <span className="font-normal">
              {asistenteData?.funcion ? asistenteData.funcion.toUpperCase() : 'Cargando...'}
            </span>
          </p>
          <p className="text-sm font-bold">
            Séctor:{' '}
            <span className="font-normal">
              {asistenteData?.sector_acceso
                ? asistenteData.sector_acceso.toUpperCase()
                : 'Cargando...'}
            </span>
          </p>
        </div>

        <div className="flex justify-between items-center w-full mb-4">
          <QRCode value={asistenteId} size={144} />
          <div className="grid grid-cols-3 gap-2 ml-4">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(zona => (
              <div
                key={zona}
                className={`rounded-md py-2 text-center w-12 text-lg font-bold ${getZonaStyles(zona)}`}
              >
                {zona}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center gap-6">
        <CustomButton
          onClick={onBack}
          variant="whiteGre"
          className="px-6 py-4 text-gray text-base font-normal leading-snug border border-outline rounded-3xl"
        >
          Atrás
        </CustomButton>
        <CustomButton
          onClick={handleGenerateCredential}
          variant="vinotinto"
          className="px-4 py-3 text-white text-base font-normal leading-snug bg-button rounded-3xl"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="primary" /> : 'Generar Credencial'}
        </CustomButton>
      </div>

      {/* Spinner de Carga */}
      <Backdrop open={loading} sx={{ zIndex: 1200 }}>
        <CircularProgress size={60} sx={{ color: 'theme("colors.skyblue")' }} />
      </Backdrop>
    </div>
  );
};

export default MassUploadCompletionStep;
