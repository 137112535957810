import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import {
  Menu as MenuIcon,
  Event as EventIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material';
import ImagenComponent from 'components/background/imageComponent';
import imageP from 'components/background/png';
import './styles.css';
import { useNavigate } from 'react-router-dom';

const Slider = ({ setActiveContent }) => {
  const [activeButton, setActiveButton] = useState('eventos');
  const navigate = useNavigate();

  const iconStyles = isActive => ({
    color: isActive ? 'black' : 'white',
  });

  const buttonStyles = isActive => ({
    width: '90%',
    display: 'flex',
    justifyContent: 'flex-start',
    color: isActive ? 'black' : 'white',
    backgroundColor: isActive ? 'white' : 'transparent',
    borderRadius: '8px',
    padding: '10px 30px',
    border: '2px solid transparent',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
      borderColor: 'transparent',
    },
    margin: '10px auto',
  });

  const handleLogoutClick = () => {
    setActiveButton('logout');
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  return (
    <AppBar
      position="static"
      className="bg-custom-gradient w-full sm:w-2/5 md:w-1/4 lg:w-1/5 h-screen overflow-y-auto absolute"
    >
      <Toolbar className="flex flex-col items-center h-full p-4">
        <IconButton edge="start" color="inherit" aria-label="menu" className="mb-4 self-start ml-4">
          <MenuIcon />
        </IconButton>

        {/* Contenedor para los botones */}
        <div className="flex flex-col items-start w-full gap-4">
          <Button
            onClick={() => {
              setActiveButton('eventos');
              setActiveContent('evento');
            }}
            style={{
              ...buttonStyles(activeButton === 'eventos'),
              margin: '10px 0',
              padding: '10px 0',
              width: '100%',
              justifyContent: 'center',
            }}
            title="Eventos"
          >
            <EventIcon className="lg:mr-2" style={iconStyles(activeButton === 'eventos')} />
            <span
              className={`lg:inline hidden ${activeButton === 'eventos' ? 'text-black' : 'text-white'}`}
            >
              Eventos
            </span>
          </Button>

          <Button
            onClick={handleLogoutClick}
            style={{
              ...buttonStyles(activeButton === 'logout'),
              margin: '10px 0',
              padding: '10px 0',
              width: '100%',
              justifyContent: 'center',
            }}
            title="Cerrar Sesión"
          >
            <ExitToAppIcon className="lg:mr-2" style={iconStyles(activeButton === 'logout')} />
            <span
              className={`lg:inline hidden ${activeButton === 'logout' ? 'text-black' : 'text-white'}`}
            >
              Cerrar Sesión
            </span>
          </Button>
        </div>

        <div className="mt-auto px-4 w-full">
          <ImagenComponent src={imageP.escudoFvf} alt="logo adaptable2" className="adp" />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Slider;
