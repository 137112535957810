import React from 'react';
import { Tooltip } from 'react-tooltip';

const InputField = ({
  label,
  placeholder,
  name,
  type = 'text',
  value,
  onChange,
  error,
  icon,
  accept,
  disabled,
}) => {
  const borderClass = error ? 'border-red-500' : 'border-heading';
  const bgClass = disabled ? 'bg-gray-200' : '';

  return (
    <div className="w-full h-auto flex flex-col justify-start items-start gap-1 relative">
      <div className="w-full px-4 justify-start items-center gap-2.5 inline-flex">
        <label htmlFor={name} className="text-label text-sm font-normal leading-normal">
          {label}
        </label>
      </div>
      <div
        className={`w-full p-4 rounded-2xl border ${borderClass} inline-flex items-center ${bgClass}`}
        data-tooltip-id={name}
        data-tooltip-content={error}
      >
        <input
          id={name}
          name={name}
          type={type}
          className="w-full text-primary text-base font-normal leading-snug focus:outline-none"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          accept={accept}
          disabled={disabled}
        />
        {icon && <div className="ml-2">{icon}</div>}
        {error && <Tooltip id={name} place="top" effect="solid" />}
      </div>
    </div>
  );
};

export default InputField;
