import { safeImageImport } from 'components/background/utils';
const fallbackImage = 'img/respaldo/fall-back.webp';

const imageS = {
  checkAdd: safeImageImport('img/svg/check-general.svg', fallbackImage),
  stepcheck: safeImageImport('img/svg/check-step.svg', fallbackImage),
  stepcircle: safeImageImport('img/svg/ellipse-step.svg', fallbackImage),
  clearIcon: safeImageImport('img/svg/clear.svg', fallbackImage),
};

export default imageS;
