import React from 'react';

const StepInfo = () => {
  return (
    <div className="flex justify-start items-start gap-4">
      <div className="flex items-center">
        <div className="w-14 h-14 p-2.5 bg-button rounded-full flex justify-center items-center">
          <div className="w-4 h-4 bg-gray rounded-full"></div>
        </div>
        <span className="ml-4 text-heading text-lg font-normal leading-normal">
          Información Básica
        </span>
      </div>
    </div>
  );
};

export default StepInfo;
