import { FaSpinner } from 'react-icons/fa';

const Button = ({
  label,
  icon: Icon,
  onClick,
  bgColor = 'bg-button',
  textColor = 'text-white',
  isLoading = false,
}) => {
  return (
    <button
      className={`px-6 py-3 ${bgColor} rounded-3xl border border-button flex items-center justify-center`}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <FaSpinner className="animate-spin text-white" />
      ) : (
        <>
          {Icon && <Icon className={`${textColor} mr-2`} />}
          <span className={textColor}>{label}</span>
        </>
      )}
    </button>
  );
};

export default Button;
