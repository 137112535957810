// src/components/SideSection.js
import React from 'react';

const SideSectionv2 = () => {
  return (
    <div className="w-full h-screen bg-custom-gradient pl-10 pt-14 pb-20 flex flex-col justify-between items-start gap-12">
      <div className="flex flex-col gap-6">
        <div className="text-white text-[28px] font-bold leading-9">
          Ingresa la información del personal que debe ser acreditado
        </div>
        <div className="text-white text-lg font-normal leading-normal">
          Completa el formulario de registro para tener acceso a tu credencial
        </div>
      </div>
      <div className="flex gap-6"></div>
    </div>
  );
};

export default SideSectionv2;
