import React, { useRef, useState, useEffect } from 'react';

const NavItem = ({ label, Icon, isSelected, onClick }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const labelRef = useRef(null);

  // Verifica texto truncado
  useEffect(() => {
    if (labelRef.current) {
      setIsTruncated(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label]);

  const buttonClass = `w-full flex items-center p-2 rounded-lg cursor-pointer 
    ${isSelected ? 'bg-white text-primary' : 'bg-transparent text-inactive'} hover:bg-gray-100`;

  return (
    <div className="relative group">
      <button onClick={onClick} className={buttonClass}>
        <div className="w-6 h-6 flex items-center justify-center">
          <Icon />
        </div>
        <span ref={labelRef} className="ml-2 text-sm font-normal truncate">
          {label}
        </span>
      </button>

      {/*  tooltip solo si el texto está truncado */}
      {isTruncated && (
        <div className="absolute left-0 mt-1 w-auto bg-tooltip-gray text-primary text-xs py-1 px-3 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-10">
          {label}
        </div>
      )}
    </div>
  );
};

export default NavItem;
