import { useState } from 'react';
import { getAllAsistentes } from 'services/upload';
import { generateCredentialsForAllAsistentes } from 'services/credencial';

// Hook de paginación
export const usePagination = (initialPage = 0, initialRowsPerPage = 5) => {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

// Configuración de columnas con abreviaturas
export const columns = [
  { field: 'nombres', headerName: 'NOMBRES', width: 150 },
  { field: 'apellidos', headerName: 'APELLIDOS', width: 150 },
  { field: 'nro_documento', headerName: 'CÉDULA', width: 120 },
  { field: 'gerencia_departamento', headerName: 'GERENCIA / DEPARTAMENTO', width: 250 },
  { field: 'proveedor', headerName: 'PROVEEDOR', width: 200 },
  { field: 'cargo', headerName: 'CARGO', width: 150 },
  { field: 'funcion', headerName: 'FUNCIÓN', width: 150 },
  { field: 'zona_accesos', headerName: 'ZONAS DE ACCESO', width: 200 },
  { field: 'telefono', headerName: 'TELÉFONO', width: 150 },
  { field: 'email', headerName: 'EMAIL', width: 200 },
];

export const getAllAsistentesData = async () => {
  try {
    const data = await getAllAsistentes();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export const generateCredentialsForAllAsistentesData = async options => {
  try {
    const result = await generateCredentialsForAllAsistentes(options);
    return result; // Retorna el resultado para manejar en el componente
  } catch (error) {
    // Maneja el error aquí, pero asegúrate de pasar el error al componente
    if (error.response) {
      return { status: error.response.status, error: error.response.data };
    } else {
      return { status: null, error: error.message || 'Error desconocido' };
    }
  }
};

export const STEP_LABELS = ['Información Básica', 'Datos cargados', 'Completado'];

// utils.js

export const scrollSettings = {
  step: 3, //velocidad de scrol
  scrollSpeed: 10, //velocidad entre casillas
};

export const animateScroll = (tableContainer, maxScroll) => {
  let scrollAmount = 0;

  const scroll = () => {
    if (scrollAmount < maxScroll) {
      tableContainer.scrollLeft += scrollSettings.step;
      scrollAmount += scrollSettings.step;

      setTimeout(scroll, scrollSettings.scrollSpeed);
    }
  };

  setTimeout(scroll, 1000); //inciar scrol automatico
};

export const setupMouseEvents = tableContainer => {
  let isDown = false;
  let startX;
  let scrollLeft;

  const mouseDownHandler = e => {
    isDown = true;
    tableContainer.classList.add('active');
    startX = e.pageX - tableContainer.offsetLeft;
    scrollLeft = tableContainer.scrollLeft;
  };

  const mouseLeaveHandler = () => {
    isDown = false;
    tableContainer.classList.remove('active');
  };

  const mouseUpHandler = () => {
    isDown = false;
    tableContainer.classList.remove('active');
  };

  const mouseMoveHandler = e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - tableContainer.offsetLeft;
    const walk = (x - startX) * 3;
    tableContainer.scrollLeft = scrollLeft - walk;
  };

  tableContainer.addEventListener('mousedown', mouseDownHandler);
  tableContainer.addEventListener('mouseleave', mouseLeaveHandler);
  tableContainer.addEventListener('mouseup', mouseUpHandler);
  tableContainer.addEventListener('mousemove', mouseMoveHandler);

  return () => {
    tableContainer.removeEventListener('mousedown', mouseDownHandler);
    tableContainer.removeEventListener('mouseleave', mouseLeaveHandler);
    tableContainer.removeEventListener('mouseup', mouseUpHandler);
    tableContainer.removeEventListener('mousemove', mouseMoveHandler);
  };
};
