// src/components/landingPage/index.js
import React, { useState } from 'react';
import EventHeader from './eventAdd';
import EventInfo from './eventInfo';

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const handleContactClick = () => {
    setShowContactForm(true);
    setMenuOpen(false); // Cerrar el menú al hacer clic en Contacto
  };

  const handleBackClick = () => {
    setShowContactForm(false);
  };

  return (
    <>
      <div className="w-full h-screen flex flex-col md:flex-row">
        {/* Contenido completo para escritorio */}
        <div className="flex w-full flex-col">
          <EventHeader />
          <EventInfo />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
