import { toast } from 'react-toastify';
import { login } from 'services/login/AuthService';

export const useLogin = navigate => {
  const handleLogin = async (email, password) => {
    try {
      const data = await login(email, password);
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('userName', data.usuario);
      localStorage.setItem('userRole', data.rol);
      toast.success('Bienvenido! ' + data.usuario);
      navigate('/dashboard');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error de autenticación';
      toast.error(errorMessage);
    }
  };

  const handleRegister = () => {
    navigate('/add');
  };

  return {
    handleLogin,
    handleRegister,
  };
};

export const texts = {
  loginTitle: 'Iniciar Sesión',
  emailLabel: 'Correo Electrónico',
  emailPlaceholder: 'Ingrese su correo electrónico',
  passwordLabel: 'Contraseña',
  passwordPlaceholder: 'Ingrese su contraseña',
  forgotPassword: '¿Olvidó su contraseña?',
  registerButton: 'Registrarse',
  loginButton: 'Iniciar Sesión',
};
