import React from 'react';
import CustomButton from 'components/button/';
import { eventDetails } from './utils';

const EventInfoText = () => {
  return (
    <div className="w-full lg:w-1/2 h-auto flex flex-col justify-center items-start p-5 lg:p-10">
      <div className="flex flex-col justify-start items-start gap-6">
        <div className="flex flex-col justify-start items-start gap-4">
          <div className="text-white text-2xl sm:text-[30px] md:text-[40px] font-normal font-['Archivo Black'] leading-snug md:leading-[48px]">
            {eventDetails.title}
          </div>
        </div>
        <div className="w-full max-w-[480px] text-white text-sm sm:text-base font-normal font-['Archivo Black'] leading-normal">
          {eventDetails.description}
        </div>
        <div className="w-full flex justify-start items-center gap-6"></div>
      </div>
    </div>
  );
};

export default EventInfoText;
