// ImageComponent.js
import React from 'react';
import PropTypes from 'prop-types';

const ImageComponent = ({ src, alt, className }) => {
  return <img className={className} src={src} alt={alt} />;
};

ImageComponent.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default ImageComponent;
