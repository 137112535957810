import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaUpload } from 'react-icons/fa';

import InputField from 'components/input';
import SectionHeaderTitle from 'components/SectionHeaderTitle';
import Button from 'components/globalButton';
import { uploadAttendeePhoto } from 'services/upload/attendees';

const UploadAttendeePhotoPage = () => {
  const navigate = useNavigate();
  const { eventoId, asistenteId } = useParams();
  const [photoFile, setPhotoFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setPhotoFile(selectedFile);
  };

  const handleBack = () => {
    if (!isLoading) {
      navigate(-1);
    }
  };

  const handleUpload = async () => {
    if (!photoFile) {
      toast.error('Por favor seleccione una foto del asistente.');
      return;
    }
    setIsLoading(true);

    try {
      await uploadAttendeePhoto(eventoId, asistenteId, photoFile);
      toast.success('La foto del asistente se ha subido correctamente.');
      setPhotoFile(null);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center bg-white">
      <SectionHeaderTitle title="Subir foto del asistente" />

      <div className="mt-6">
        <InputField
          label="Foto del asistente"
          name="fileUpload"
          type="file"
          icon={<FaUpload className="text-gray-400" />}
          onChange={handleFileChange}
          accept=".pgn, .jpg, .jpeg"
        />
      </div>

      <div className="flex gap-4 mt-8">
        <Button
          label="Atrás"
          onClick={handleBack}
          bgColor="bg-white"
          textColor="text-black"
          isLoading={isLoading}
        />
        <Button label="Confirmar" onClick={handleUpload} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default UploadAttendeePhotoPage;
