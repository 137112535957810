// CustomButton.js
import React from 'react';
import { Button } from '@mui/material';
import { baseStyles, menuButtonStyles, variantStyles } from './utils';

const CustomButton = ({ children, variant, className, ...props }) => {
  const combinedStyles = {
    ...baseStyles,
    ...(variant === 'menuButton' ? menuButtonStyles : variantStyles[variant]),
  };

  return (
    <Button variant="outlined" className={`font-sans ${className}`} sx={combinedStyles} {...props}>
      {children}
    </Button>
  );
};

export default CustomButton;
