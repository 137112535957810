import apiClient from 'services/AxiosConfig';

export const login = async (email, password) => {
  try {
    const response = await apiClient.post('/auth/login', {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

export const forgotPassword = async usuario_email => {
  try {
    const response = await apiClient.post('/auth/forgot-password', {
      usuario_email: usuario_email,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

export const verifyToken = async token => {
  try {
    const response = await apiClient.post('/auth/verify-token', { token });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};
