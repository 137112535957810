// App.js
import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import theme from './theme';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from 'components/login/Login';
import AddAttendeePage from 'components/pages/AddAttendee';
import AttendeesFileUploadForm from 'components/forms/AttendeesFileUploadForm';
import AssignZonesPage from 'components/pages/AssignZonesPage';
import UploadAttendeePhotoPage from 'components/pages/UploadAttendeePhotoPage';

import SidebarLayout from 'components/layouts/sidebar-layout';
import Load from 'components/load/';
import Dashboard from 'components/main/Dashboard';
import LandingPage from 'components/landingPage';
import PrivateRoute from 'components/security';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />

          {/* Layout con Sidebar y Outlet para cargar el contenido */}
          <Route path="/eventos/:eventoId" element={<SidebarLayout />}>
            <Route path="asistentes" element={<Load />} />
            <Route path="asistentes/carga-masiva" element={<AttendeesFileUploadForm />} />
            <Route path="asistentes/carga-individual" element={<AddAttendeePage />} />
            <Route path="asistentes/:asistenteId/asignar-zonas" element={<AssignZonesPage />} />
            <Route
              path="asistentes/:asistenteId/subir-foto"
              element={<UploadAttendeePhotoPage />}
            />
            <Route
              path="asistentes/credenciales"
              element={
                <div>
                  <h1>Trabajo en progreso ...</h1>
                </div>
              }
            />
          </Route>

          {/* Rutas protegidas */}
          <Route
            path="/load"
            element={
              <PrivateRoute>
                <Load />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
