import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
  Modal,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Icono de tres puntos
import {
  columns,
  usePagination,
  getAllAsistentesData,
  animateScroll,
  setupMouseEvents,
} from './utils';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { cancelGeneration } from 'services/credencial';
import SectionHeaderTitle from 'components/SectionHeaderTitle';

const MassUploadResultStep = ({ onContinue }) => {
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const navigate = useNavigate();

  const tableContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getAllAsistentesData();
        setUploadedData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error al obtener los datos.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const paginatedData = uploadedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleMenuClick = (event, row) => {
    setSelectedRow(row);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handlePreview = id => {
    onContinue(id);
    handleMenuClose();
  };

  const { eventoId } = useParams();
  const handleAssignZones = asistenteId => {
    navigate(`/eventos/${eventoId}/asistentes/${asistenteId}/asignar-zonas`);
    handleMenuClose();
  };

  const handleUploadPhoto = asistenteId => {
    navigate(`/eventos/${eventoId}/asistentes/${asistenteId}/subir-foto`);
    handleMenuClose();
  };

  const handleCancel = async () => {
    try {
      const response = await cancelGeneration();

      if (response && response.status === 200) {
        toast.info('Cancelación solicitada exitosamente.');
      } else {
        console.error('Error inesperado al cancelar el proceso.');
        toast.error('No se pudo cancelar el proceso. Inténtalo de nuevo.');
      }
    } catch (error) {
      console.error('Error en la solicitud de cancelación:', error.message);
      toast.error('No se pudo cancelar el proceso. Inténtalo de nuevo.');
    } finally {
      setGenerating(false);
    }
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    if (tableContainer) {
      const maxScroll = tableContainer.scrollWidth - tableContainer.clientWidth;

      animateScroll(tableContainer, maxScroll);
      const cleanupMouseEvents = setupMouseEvents(tableContainer);

      return () => {
        cleanupMouseEvents();
      };
    }
  }, []);

  return (
    <div className="w-full min-h-screen bg-[#feffff] rounded-tl-3xl rounded-bl-3xl flex flex-col items-center gap-6">
      <SectionHeaderTitle title="Asistentes" />

      <Box
        sx={{
          width: '98%',
          maxWidth: '1600px',
          backgroundColor: 'transparent',
          overflowX: 'hidden',
          overflowY: 'hidden',
          flexGrow: 1,
          position: 'relative',
        }}
      >
        <Paper elevation={0} className="paper" sx={{ height: '100%' }}>
          <TableContainer
            className="table-container no-scrollbar"
            ref={tableContainerRef}
            sx={{ height: '100%', maxHeight: 'none' }}
          >
            <Table sx={{ width: '100%', height: '100%' }}>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.field}
                      className="no-select"
                      sx={{
                        minWidth: column.width,
                        maxWidth: column.width,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '50px',
                        lineHeight: '50px',
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                  {/* Nueva cabecera para las acciones */}
                  <TableCell
                    className="no-select"
                    sx={{
                      minWidth: '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      height: '50px',
                      lineHeight: '50px',
                      textAlign: 'center',
                    }}
                  >
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="table-body">
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={columns.length + 2}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData.map((row, index) => (
                    <TableRow key={index} hover sx={{ backgroundColor: 'white', height: '50px' }}>
                      {columns.map(column => (
                        <TableCell
                          key={column.field}
                          className="table-cell"
                          sx={{
                            padding: '8px',
                            lineHeight: '1.2',
                            height: '50px',
                            position: 'relative',
                          }}
                        >
                          {row[column.field]}
                        </TableCell>
                      ))}

                      {/* Columna del icono de tres puntos */}
                      <TableCell
                        sx={{
                          width: '50px',
                          position: 'relative',
                        }}
                      >
                        <IconButton
                          onClick={event => handleMenuClick(event, row)}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '50%',
                            transform: 'translate(50%, -50%)',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            '&:hover': {
                              backgroundColor: '#bdbdbd',
                            },
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl}
                          open={Boolean(menuAnchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              handlePreview(selectedRow?.asistente_id);
                            }}
                          >
                            Previsualizar credencial
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleAssignZones(selectedRow?.asistente_id);
                            }}
                          >
                            Asignar zonas
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleUploadPhoto(selectedRow?.asistente_id);
                            }}
                          >
                            Subir foto
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                )}

                {/* Agregar filas vacías si es necesario */}
                {paginatedData.length < rowsPerPage &&
                  Array.from(Array(rowsPerPage - paginatedData.length)).map((_, index) => (
                    <TableRow
                      key={`empty-row-${index}`}
                      sx={{ backgroundColor: 'white', height: '40px' }}
                    >
                      <TableCell colSpan={columns.length + 2}></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={uploadedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          m: 2,
          overflow: 'visible',
        }}
      />

      {/* Modal con spinner y botón de cancelar */}
      <Modal open={generating} onClose={handleCancel}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: '12px',
            p: 4,
            boxShadow: 24,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '300px',
          }}
        >
          <CircularProgress color="primary" sx={{ mb: 2 }} />
          <button onClick={handleCancel} className="bg-[#520e27] text-white px-4 py-2 rounded-md">
            Cancelar
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default MassUploadResultStep;
