import React from 'react';
import { eventDetails } from 'components/landingPage/eventAdd/utils';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';

const EventCalendar = () => {
  return (
    <div className="w-full md:w-[412px] p-4 sm:p-8 border-2 border-amber-50 flex flex-col justify-start items-start gap-4">
      <div className="w-full flex justify-start items-start gap-2">
        <DateRangeIcon className="text-amber-50" />
        <div className="flex-grow flex-shrink-0 flex flex-col justify-start items-start gap-1">
          <div className="text-amber-50 text-lg sm:text-[22px] font-normal">
            {eventDetails.date}
          </div>
          <div className="text-amber-50 text-base font-normal">{eventDetails.time}</div>
        </div>
      </div>
      <div className="w-full flex justify-start items-start gap-2">
        <PlaceIcon className="text-amber-50" />
        <div className="flex-grow flex-shrink-0 flex flex-col justify-start items-start gap-1">
          <div className="text-amber-50 text-base font-normal leading-snug">
            {eventDetails.location}
          </div>
          <div className="text-amber-50 text-base font-normal leading-snug">
            {eventDetails.city}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
