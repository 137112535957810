import React from 'react';
import EventInfoImage from './image';
import EventInfoText from './text';
import imageP from 'components/background/png';

const EventInfo = () => {
  return (
    <div
      className="flex flex-col lg:flex-row justify-start items-start lg:items-center w-full h-screen relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${imageP.fondoVinotinto})` }}
    >
      <EventInfoImage />
      <EventInfoText />
    </div>
  );
};

export default EventInfo;
