import apiClient from 'services/AxiosConfig';

// Método para la carga masiva de usuarios desde un archivo Excel
export const uploadUsuarios = async file => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await apiClient.post('/upload/usuarios', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};

// Nuevo método para obtener todos los asistentes registrados
export const getAllAsistentes = async () => {
  try {
    const response = await apiClient.get('/upload/usuarios');
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response;
    } else {
      throw error;
    }
  }
};
