import apiClient from 'services/AxiosConfig';
import axios from 'axios';

// Método para la carga masiva de asistentes desde un archivo CSV
export const uploadAttendees = async (eventId, file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await apiClient.post(`/events/${eventId}/attendees/uploads`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    // Aquí al error.data
    if (error.data && error.data.message) {
      throw {
        statusCode: error.data.message.statusCode,
        message: error.data.message.message,
        error: error.data.message.error,
      };
    } else {
      throw {
        statusCode: 500,
        message: 'Error desconocido. Por favor intenta de nuevo más tarde.',
      };
    }
  }
};

// Método para registrar un asistente
export const createAttendee = async (eventId, attendeeData) => {
  try {
    const response = await apiClient.post(`/events/${eventId}/attendees`, attendeeData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (error.data && error.data.message) {
      throw {
        statusCode: error.data.message.statusCode,
        message: error.data.message.message,
        error: error.data.message.error,
      };
    } else {
      throw {
        statusCode: 500,
        message: 'Error desconocido. Por favor intenta de nuevo más tarde.',
      };
    }
  }
};

// Método para actualizar las zonas de un asistente
export const updateAttendeeZones = async (eventId, attendeeId, data) => {
  try {
    const response = await apiClient.patch(`/events/${eventId}/attendees/${attendeeId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (error.data && error.data.message) {
      throw {
        statusCode: error.data.message.statusCode,
        message: error.data.message.message,
        error: error.data.message.error,
      };
    } else {
      throw {
        statusCode: 500,
        message: 'Error desconocido. Por favor intenta de nuevo más tarde.',
      };
    }
  }
};

// Método para obtener todos los niveles de acceso
export const getAllAccessZones = async () => {
  try {
    const response = await apiClient.get('/credential-access/all', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Aquí devuelve la lista de niveles de acceso
  } catch (error) {
    if (error.data && error.data.message) {
      throw {
        statusCode: error.data.message.statusCode,
        message: error.data.message.message,
        error: error.data.message.error,
      };
    } else {
      throw {
        statusCode: 500,
        message: 'Error desconocido. Por favor intenta de nuevo más tarde.',
      };
    }
  }
};

const handleError = error => {
  if (error.data?.message) {
    throw {
      statusCode: error.data.message.statusCode,
      message: error.data.message.message,
      error: error.data.message.error,
    };
  } else {
    throw {
      statusCode: 500,
      message:
        'Ha ocurrido un error al subir la foto del asistente. Por favor intenta de nuevo más tarde.',
    };
  }
};

export const uploadAttendeePhoto = async (eventId, attendeeId, photoFile) => {
  const endpointUrl = `/events/${eventId}/attendees/${attendeeId}/signed-url`;

  try {
    // Getting the signed url to upload
    const response = await apiClient.get(endpointUrl);
    const { url: signedUploadUrl, fileName } = response.data;

    // Upload to the AWS S3 bucket directly using the signed upload url
    await axios.put(signedUploadUrl, photoFile, {
      headers: {
        'Content-Type': photoFile.type,
      },
    });
  } catch (error) {
    handleError(error);
  }
};
